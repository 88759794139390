module.exports = [{
      plugin: require('/codebuild/output/src001624203/src/bitbucket.org/teamgeek/sis-web/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-51287640-2","head":false,"anonymize":true},
    },{
      plugin: require('/codebuild/output/src001624203/src/bitbucket.org/teamgeek/sis-web/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"880174318996023"},
    },{
      plugin: require('/codebuild/output/src001624203/src/bitbucket.org/teamgeek/sis-web/node_modules/gatsby-plugin-twitter-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"o2bhy"},
    },{
      plugin: require('/codebuild/output/src001624203/src/bitbucket.org/teamgeek/sis-web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
