import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost"
import { Auth } from "aws-amplify"
import fetch from "isomorphic-unfetch"

import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory"
import introspectionQueryResultData from "./fragmentTypes.json"

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

let apolloClient = null

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch
}

const link = new HttpLink({
  uri: `${process.env.GRAPHQL_URI}`,
  fetch: async (uri, options) => {
    const session = await Auth.currentSession()
    options.headers["Authorization"] = session.idToken.jwtToken
    return fetch(uri, options)
  },
})

const create = (initialState = {}) => {
  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // disable forceFetch on the server (so queries are only run once)
    link,
    cache: new InMemoryCache({ fragmentMatcher }).restore(initialState),
  })
}

const createApolloClient = initialState => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState)
  }

  return apolloClient
}

export default createApolloClient
