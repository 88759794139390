import React from "react"
import styled from "styled-components"

import paper from "./paper.jpg"
import grundge from "./grundge_s.png"

const Container = styled.div`
  /* z-index: 99; // not sure why this is not working as expected */
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: url(${grundge});
  background-size: auto auto;
  &:before {
    content: "";
    mix-blend-mode: multiply;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(${paper});
    background-size: 100% auto;
  }
`

const Paper = () => {
  return <Container />
}

export default Paper
