import "swiper/dist/css/swiper.min.css"
import "./src/styles/theme.css"

import configureAmplify from "./src/configureAmplify"
import wrapRootElement from "./src/wrapRootElement"
import wrapPageElement from "./src/wrapPageElement"

configureAmplify()

export { wrapRootElement, wrapPageElement }
