import { Auth, Logger } from "aws-amplify"
import { useDispatch } from "react-redux"
import React, { useEffect, useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { setUser } from "../../state"

import Paper from "./Paper"
import Logo from "./Logo"
import Cursor from "./Cursor"
import Notification from "../elements/Notification"

const Container = styled.div`
  position: relative;
  background-color: rgba(0, 93, 107, 0.99);
`

const Layout = ({ children }) => {
  const dispatch = useDispatch()
  const logger = useMemo(() => new Logger(Layout.name), [])

  const fetchAuthenticatedUser = useCallback(async () => {
    logger.warn("CALLING FETCH USER FROM LAYOUT")
    try {
      const user = await Auth.currentAuthenticatedUser()
      logger.debug(user)
      dispatch(setUser({ user: user.attributes, hasUser: true }))
    } catch (err) {
      logger.error(err)
      dispatch(setUser({ hasUser: true }))
    }
  }, [dispatch, logger])

  useEffect(() => {
    fetchAuthenticatedUser()
  }, [fetchAuthenticatedUser])

  return (
    <Container>
      {children}
      <Paper />
      <Logo />
      <Cursor />
      <Notification />
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
