import React from "react"
import PropTypes from "prop-types"

import { ApolloProvider } from "react-apollo"
import { Provider } from "react-redux"
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks"

import { createApolloClient } from "./graphql"
import { createStore } from "./state"

export const wrapRootElement = ({ element }) => {
  const store = createStore()
  const apolloClient = createApolloClient()
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          {element}
        </ApolloHooksProvider>
      </ApolloProvider>
    </Provider>
  )
}

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
}

export default wrapRootElement
