import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { isBrowser } from "react-device-detect"
import { TweenLite } from "gsap"

import "./stylesheet.css"

const Container = styled.div`
  pointer-events: none;
  position: fixed;
  left: 10px;
  top: 10px;
  z-index: 9999999;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  background-color: rgba(246, 86, 100, 1);
  mix-blend-mode: difference;
  transform: scale(0);
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform;
  &.active {
    transform: scale(1);
    &.hover {
      transform: scale(2);
    }
  }
`

const Cursor = () => {
  const pointer = useRef(null)
  const frameId = useRef(null)
  const clientX = useRef(0)
  const clientY = useRef(0)
  const timeOut = useRef(null)

  const moveMouse = () => {
    enableCursor()
    TweenLite.set(pointer.current, {
      left: clientX.current,
      top: clientY.current,
    })
    if (timeOut.current !== null) {
      window.clearTimeout(timeOut.current)
    }
    timeOut.current = window.setTimeout(() => {
      disableCursor()
    }, 3000)
  }

  const onMouseMove = e => {
    clientX.current = e.clientX - 8
    clientY.current = e.clientY - 8
    frameId.current = window.requestAnimationFrame(moveMouse)
  }

  const disableCursor = () => {
    if (pointer.current) {
      pointer.current.classList.remove("active")
    }
  }
  const enableCursor = () => {
    if (!pointer.current.classList.contains("active")) {
      pointer.current.classList.add("active")
    }
  }

  const onLinkEnter = () => {
    pointer.current.classList.add("hover")
  }

  const onLinkLeave = () => {
    pointer.current.classList.remove("hover")
  }

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("mousemove", onMouseMove, false)
      return () => {
        window.removeEventListener("mousemove", onMouseMove, false)
        window.cancelAnimationFrame(frameId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isBrowser) {
      pointer.current.classList.remove("hover")
      // console.log("Adding new mouse listeners")
      const mouseLinks = document.getElementsByClassName("mouse-link")
      Array.from(mouseLinks).forEach(link => {
        link.addEventListener("mouseenter", onLinkEnter, false)
        link.addEventListener("mouseleave", onLinkLeave, false)
      })
      return () => {
        // console.log("Removing new mouse listeners")
        Array.from(mouseLinks).forEach(link => {
          link.removeEventListener("mouseenter", onLinkEnter, false)
          link.removeEventListener("mouseleave", onLinkLeave, false)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  if (isBrowser) {
    return <Container ref={pointer} />
  } else {
    return null
  }
}

export default Cursor

/*

  hide cursor from main theme styles file

  Add and remove classLists on route change
  create a use effect that watches for location change
  as you may neda re catch all of the elements with mouse link class names
*/
