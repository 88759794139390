import { combineReducers } from "redux"
import { createReducer } from "redux-starter-kit"

import { notify, setUser } from "./actions"

const notifications = createReducer(
  {
    notification: null,
  },
  {
    [notify]: (state, action) => {
      state.notification = action.payload
    },
  }
)

const auth = createReducer(
  {
    user: null,
    hasUser: false,
  },
  {
    [setUser]: (state, action) => {
      const { user = null, hasUser } = action.payload
      state.user = user
      state.hasUser = hasUser
    },
  }
)

const rootReducer = combineReducers({
  notifications,
  auth,
})

export default rootReducer
